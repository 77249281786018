export enum DialogType {
  DialogChapterDescription = "DialogChapterDescription",
  DialogFindPassword = "DialogFindPassword",
  DialogFormerUser = "DialogFormerUser",
  DialogReview = "DialogReview",
  DialogIssueCoupon = "DialogIssueCoupon",
  DialogPassword = "DialogPassword",
  DialogLeaveUser = "DialogLeaveUser",
  DialogCoupon = "DialogCoupon",
  DialogKDigitalCredit = "DialogKDigitalCredit",
  DialogSubscribeCancel = "DialogSubscribeCancel",
  DialogSubscribeChange = "DialogSubscribeChange",
  DialogSubscribePaymentMethodChange = "DialogSubscribePaymentMethodChange",
  DialogSubscribePaymentInfoAdded = "DialogSubscribePaymentInfoAdded",
  DialogOverallScoreSetting = "DialogOverallScoreSetting",
  DialogDisableCommunity = "DialogDisableCommunity",
  DialogProhibitedWords = "DialogProhibitedWords",
  DialogPrintCertificates = "DialogPrintCertificates",
  DialogCellPhoneAuth = "DialogCellPhoneAuth",
  DialogTerms = "DialogTerms",
  DialogScamWarning = "DialogScamWarning"
}
