import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AsyncComponent } from "vue";
import { DialogType } from "@/enum/DialogType";
import DialogModel from "@/components/common/modal/model/DialogModel";

@Module({ namespaced: true, name: "dialog" })
export default class Dialog extends VuexModule {
  public isAutoClose = true;
  public isBackgroundClickClose = true;
  public isOpen = false;
  public dialog: AsyncComponent | string = "";
  public model: DialogModel = this.emptyAlert();
  public dialogType: DialogType | string = "";

  public dialogs: Record<DialogType, AsyncComponent> = {
    [DialogType.DialogReview]: () =>
      import(
        /* webpackChunkName: "DialogReview" */ "@/components/common/modal/dialog/DialogReview.vue"
      ),
    [DialogType.DialogPassword]: () =>
      import(
        /* webpackChunkName: "DialogPassword" */ "@/components/common/modal/dialog/DialogPassword.vue"
      ),
    [DialogType.DialogFormerUser]: () =>
      import(
        /* webpackChunkName: "DialogFormerUser" */ "@/components/common/modal/dialog/DialogFormerUser.vue"
      ),
    [DialogType.DialogFindPassword]: () =>
      import(
        /* webpackChunkName: "DialogFindPassword" */ "@/components/common/modal/dialog/DialogFindPassword.vue"
      ),
    [DialogType.DialogCoupon]: () =>
      import(
        /* webpackChunkName: "DialogCoupon" */ "@/components/common/modal/dialog/DialogCoupon/DialogCoupon.vue"
      ),
    [DialogType.DialogChapterDescription]: () =>
      import(
        /* webpackChunkName: "DialogChapterDescription" */ "@/components/common/modal/dialog/DialogChapterDescription.vue"
      ),
    [DialogType.DialogIssueCoupon]: () =>
      import(
        /* webpackChunkName: "DialogIssueCoupon" */ "@/components/common/modal/dialog/DialogIssueCoupon.vue"
      ),
    [DialogType.DialogLeaveUser]: () =>
      import(
        /* webpackChunkName: "DialogLeaveUser" */ "@/components/common/modal/dialog/DialogLeaveUser.vue"
      ),
    [DialogType.DialogKDigitalCredit]: () =>
      import(
        /* webpackChunkName: "DialogKDigitalCredit" */ "@/components/common/modal/dialog/DialogKDigitalCredit.vue"
      ),
    [DialogType.DialogSubscribeCancel]: () =>
      import(
        /* webpackChunkName: "DialogSubscribeCancel" */ "@/components/common/modal/dialog/DialogSubscribeCancel.vue"
      ),
    [DialogType.DialogSubscribeChange]: () =>
      import(
        /* webpackChunkName: "DialogSubscribeChange" */ "@/components/common/modal/dialog/DialogSubscribeChange/DialogSubscribeChange.vue"
      ),
    [DialogType.DialogSubscribePaymentMethodChange]: () =>
      import(
        /* webpackChunkName: "DialogSubscribePaymentMethodChange" */ "@/components/common/modal/dialog/DialogSubscribePaymentMethodChange.vue"
      ),
    [DialogType.DialogSubscribePaymentInfoAdded]: () =>
      import(
        /* webpackChunkName: "DialogSubscribePaymentInfoAdded" */ "@/components/common/modal/dialog/DialogSubscribePaymentInfoAdded.vue"
      ),
    [DialogType.DialogOverallScoreSetting]: () =>
      import(
        /* webpackChunkName: "DialogOverallScoreSetting" */ "@/components/common/modal/dialog/DialogOverallScoreSetting.vue"
      ),
    [DialogType.DialogDisableCommunity]: () =>
      import(
        /* webpackChunkName: "DialogDisableCommunity" */ "@/components/common/modal/dialog/DialogDisableCommunity.vue"
      ),
    [DialogType.DialogProhibitedWords]: () =>
      import(
        /* webpackChunkName: "DialogProhibitedWords" */ "@/components/common/modal/dialog/DialogProhibitedWords.vue"
      ),
    [DialogType.DialogPrintCertificates]: () =>
      import(
        /* webpackChunkName: "DialogPrintCertificates" */ "@/components/common/modal/dialog/DialogPrintCertificates.vue"
      ),
    [DialogType.DialogCellPhoneAuth]: () =>
      import(
        /* webpackChunkName: "DialogCellPhoneAuth" */ "@/components/common/modal/dialog/DialogCellPhoneAuth.vue"
      ),
    [DialogType.DialogTerms]: () =>
      import(
        /* webpackChunkName: "DialogTerms" */ "@/components/common/modal/dialog/DialogTerms.vue"
      ),
    [DialogType.DialogScamWarning]: () =>
      import(
        /* webpackChunkName: "DialogScamWarning" */ "@/components/common/modal/dialog/DialogScamWarning.vue"
      )
  };

  @Mutation
  public setIsAutoClose(isAutoClose: boolean): void {
    this.isAutoClose = isAutoClose;
  }

  @Mutation
  public open(dialogModel: DialogModel): void {
    this.dialog = this.dialogs[dialogModel.type as DialogType];
    this.model = dialogModel;
    this.isOpen = true;
    this.dialogType = dialogModel.type;
  }

  @Mutation
  public close(): void {
    this.isOpen = false;
    this.dialog = "";
    this.model.cancel?.callback();
    this.dialogType = "";
  }

  public emptyAlert(): DialogModel {
    return { props: {}, type: "" };
  }
}
